import React from "react";
import _ from "lodash";
import scroll from "scroll";
const page = require("scroll-doc")();
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import AppContainer from "../AppContainer";
import Card from "../Card";
import List from "../List";
import { TextInput, Select } from "../Inputs";
import PaymentMethodInput from "../PaymentMethodInput";
import Loader from "../Loader";
import ContactDetails from "../ContactDetails";
import ErrorBlock from "../ErrorBlock";
import Button from "../Button";
import PaymentAPIScriptLoader, {
  shouldLoadScript,
} from "../PaymentAPIScriptLoader";
import { PAYMENT_ID } from "../../api-services/constants";
import { spreedlyTokenize, recache } from "../../api-services/spreedlyAPI";
import { PAYMENT } from "../../utils/constants";
import { setParams, getParams, navigateTo, removeParams } from "../../utils/location";
import * as styles from "./index.module.scss";
import { LOGIN_TYPES } from "../LoginView";
import { getAppMediaSrc, mediaTypes } from "../../utils/media";
import SelectInput from "../Inputs/Select";
import ToastHostedAPI from "../../api-services/toastHosted";


const FIELDS = [
  PAYMENT.PAYMENT_METHOD_INPUT,
];

const PAYMENT_DETAILS = "PAYMENT_DETAILS";


const SAME_PHONE_NUMBER_AS_USER_ERROR =
  "You can not send yourself a gift, please change the recipient details";

const errorkey = (key) => `${key}_ERROR`;

export default class SubscriptionView extends React.Component {
  state = {
    ...FIELDS.reduce(
      (o, key) => ({ ...o, [key]: null, [errorkey(key)]: null }),
      {},
    ),
    shouldLoadPayment: false,
    errorComponent: null,
    animateErrorElement: false,
    chargeMultiple: 1,
    validatingForm: false,
    reloadCardNumber: undefined,
    subscriptionProgram: {},
    initToastHosted: true,
  };

  cardRefs = {};
  fieldsRefs = {};
  ToastAPI = null;

  componentDidMount() {
    const { user = {}, location, paymentTypeDetails, subscriptionPolicies } = this.props;
    const params = getParams(location);

    scroll.top(page);

    if (subscriptionPolicies && subscriptionPolicies.length == 1){
      this.setState({
        shouldLoadPayment: shouldLoadScript(paymentTypeDetails),
        subscriptionProgram: _.get(_.first(subscriptionPolicies), "id")
      });
    }else if (user.loggedIn && _.get(this.props, "user.loyaltyProfile")){
      if (_.get(user, "loyaltyProfile.data.subscriptions")
        && !_.isEmpty(_.filter(_.get(user, "loyaltyProfile.data.subscriptions"), subscription => _.get(subscription, "isActive")))) {
          const alreadySubscribedSubscription = _.get(_.first(_.filter(_.get(user, "loyaltyProfile.data.subscriptions"), sub => _.get(sub, "originPolicyId"))), "originPolicyId")
          this.setState({
            shouldLoadPayment: shouldLoadScript(paymentTypeDetails),
            subscriptionProgram: alreadySubscribedSubscription ? alreadySubscribedSubscription : undefined
          });
        }
    }

    if (user.loggedIn) {
      this.props.loadPaymentMethods(_.get(paymentTypeDetails, "paymentType"));
      this.props.loadLoyaltyProfile()
    }
  }

  componentDidUpdate(prevProps) {
    const { user = {}, subscriptionPolicies, paymentTypeDetails } = this.props;

    if (user.loggedIn && _.get(prevProps, "user.loyaltyProfile") != _.get(this.props, "user.loyaltyProfile")){
      if (_.get(user, "loyaltyProfile.data.subscriptions")
        && !_.isEmpty(_.filter(_.get(user, "loyaltyProfile.data.subscriptions"), subscription => _.get(subscription, "isActive")))) {
          const alreadySubscribedSubscription = _.get(_.first(_.filter(_.get(user, "loyaltyProfile.data.subscriptions"), sub => _.get(sub, "originPolicyId"))), "originPolicyId")
          this.setState({
            subscriptionProgram: alreadySubscribedSubscription ? alreadySubscribedSubscription : (subscriptionPolicies && subscriptionPolicies.length == 1) ? _.get(_.first(subscriptionPolicies), "id") : undefined
          });
        }
    }

    if (this.props.paymentTypeDetails?.paymentType === PAYMENT_ID.TOAST_HOSTED) {
      const chosenSubscriptionPolicy = _.find(subscriptionPolicies, policy => _.get(policy, "id") === this.state.subscriptionProgram);
      
      if (!chosenSubscriptionPolicy.price) return;

      if (this.props.paymentTypeDetails?.paymentType === PAYMENT_ID.TOAST_HOSTED && this.state.paymentLoaded) {
        this.ToastAPI = new ToastHostedAPI();
      }

      if (
        this.ToastAPI &&
        !_.isEqual(
          prevProps.order.openPaymentTokens.data,
          this.props.order.openPaymentTokens.data
        ) && this.props.order.openPaymentTokens.data
      ) {
        this.ToastAPI.update();
      }

      if (this.state.initToastHosted) {
        this.setState({ initToastHosted: false }, () => {
          this.props.openPayment({
            amount: chosenSubscriptionPolicy.price * 100,
            user,
            paymentTypeIdentifier: paymentTypeDetails?.paymentType,
            isSubscription: true,
          });
        });
      }
    }
  }

  onInputError = (fieldName) => (error) =>
    this.setState({ [`${fieldName}_ERROR`]: error });

  onInputValid = (fieldName) => (value) => {
    this.setState({ [`${fieldName}_ERROR`]: null, [fieldName]: value });
  };

  registerInput = (fieldName) => (ref) => {
    this.fieldsRefs = { ...this.fieldsRefs, [fieldName]: ref };
  };

  isValidField = (field) => !this.state[errorkey(field)];

  getInputPropsFor = (inputId, refKey = "refEl") => ({
    [refKey]: this.registerInput(inputId),
    onValid: this.onInputValid(inputId),
    onError: this.onInputError(inputId),
    rtl: this.props.appStyles.rtl,
    T: this.props.T,
  });

  scrollToErrorComponent = () => {
    scroll.top(
      page,
      _.get(this.cardRefs, `[${this.state.errorComponent}].offsetTop`) - 150,
      () => {
        this.setState({ animateErrorElement: true }, () => {
          clearTimeout(this.elementAnimation);
          this.elementAnimation = setTimeout(
            () => this.setState({ animateErrorElement: false }),
            300,
          );
        });
      },
    );
  };

  isOneToOneRatio = () => {
    const {
      pageContext: {
        business: {
          openChargeCard: { cardAmount, price },
        },
      },
    } = this.props;

    const basePrice = _.get(cardAmount, "amount");
    return basePrice === 1 && basePrice === _.get(price, "amount");
  };
    
  preTokenizeOrRecacheIfNeeded = () => {
    if (
      this.props.paymentTypeDetails?.paymentType === PAYMENT_ID.TOAST_HOSTED
    ) {
      return this.ToastAPI.addPaymentMethod(this.props.checkoutPriceDetails)
        .then((paymentMethodId) => {
          this.setState({
            [PAYMENT.PAYMENT_METHOD_INPUT]: {
              creditCard: {
                token: paymentMethodId,
                // month: exp_month,
                // year: exp_year,
                // zipCode: billing_details.address.postal_code,
                // last_four_digits: last4
              },
            },
          });
          return paymentMethodId;
        })
        .then((paymentMethodId) => {
          return this.ToastAPI.confirmPayment().then((payment) => {
            console.log("CONFIRMED PAYMENT:", payment);
            return { 
              paymentId: paymentMethodId,
              paymentToken: this.props.order.openPaymentTokens.data.paymentId
            };
          });
        });
    }

    if (
      _.includes(
        [PAYMENT_ID.SPREEDLY_TOAST, PAYMENT_ID.SPREEDLY_PURCHASE],
        this.props.paymentTypeDetails.paymentType,
      ) &&
      this.isCurrentPaymentMethodRequireCVV()
    ) {
      if (this.state[PAYMENT.PAYMENT_METHOD_INPUT].spreedlyRecache) {
        return recache();
      }
      return spreedlyTokenize({
        fullName: _.get(this.props.user, "userDetails.data.name"),
        ..._.pick(this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard, [
          "month",
          "year",
          "zipCode",
        ]),
      });
    } else if (
      this.isCardConnect() &&
      document.getElementById("cardConnectToken")
    ) {
      const token = _.get(this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard, "token");
      this.setState({
        [PAYMENT.PAYMENT_METHOD_INPUT]: {
          creditCard: {
            ..._.pick(this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard, [
              "zipCode", "month", "year"
            ]),
          },
        },
      });

      return Promise.resolve({
        token,
        paymentMethod: {
          ..._.pick(this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard, [
            "zipCode", "month", "year"]),
          last_four_digits: token.substr(-4),
        },
      });
    }
    return Promise.resolve();
  };

  isSpreedly = () =>
    _.includes(
      [PAYMENT_ID.SPREEDLY_TOAST, PAYMENT_ID.SPREEDLY_PURCHASE],
      this.props.paymentTypeDetails.paymentType,
    );

  isCardConnect = () => {
    return (
      _.get(this.props, "paymentTypeDetails.paymentType") ===
      PAYMENT_ID.CARD_CONNECT
    );
  };

  onSubmit = () => {
    if (this.state.shouldLoadPayment && !this.state.paymentLoaded) {
      console.error(
        "not handled, payment script should be loaded but it failed to load",
      );
      return;
    }

    this.setState({ validatingForm: true });

    Promise.all(
      _.map(
        FIELDS,
        (field) =>
          new Promise((resolve) => {
            console.log(field);
            return this.fieldsRefs[field].validate((err, value) => {
              this.setState(
                {
                  [field]: value,
                  [errorkey(field)]: err,
                },
                resolve,
              );
            })
          }),
      ),
    ).then(() => {
      if (!this.isValidField(PAYMENT.PAYMENT_METHOD_INPUT)) {
        this.setState(
          { errorComponent: PAYMENT_DETAILS, validatingForm: false },
          () => {
            this.scrollToErrorComponent();
          },
        );
        console.log("Invalid Form - payment details");
        return;
      }

      console.log("Valid Form");

      const { user, paymentTypeDetails } = this.props;

      this.preTokenizeOrRecacheIfNeeded().then((tokenResponse) => {
        if (_.get(tokenResponse, "token")){
          this.props.addPaymentMethod({
            creditCard: this.isSpreedly()
              ? _.pick(this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard, [
                "month",
                "year",
              ])
              : this.isCardConnect()
                ? {
                  year: _.get(
                    this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard,
                    "year",
                  ),
                  month: _.get(
                    this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard,
                    "month",
                  ),
                  zipCode: _.get(
                    this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard,
                    "zipCode",
                  ),
                }
                : this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard,
            user,
            paymentTypeDetails,
            tokenResponse,
          }).then((tokenResponse) => {
            const subscriptionParams = {
              originPolicyId: this.state.subscriptionProgram,
              isActive: true,
              chosenPaymentMethodToken: _.get(this.props.user, "paymentMethods.data[0].token")
            };
            this.props.saveSubscriptionForUser(subscriptionParams);
          })
            .catch((err) => {
              console.log(err);
              this.setState({ validatingForm: false });
            });
        }else{
          const chosenPaymentMethodToken =
            PAYMENT_ID.TOAST_HOSTED === paymentTypeDetails?.paymentType
              ? tokenResponse.paymentId
              : _.get(
                  this.props.user,
                  "paymentMethods.data[0].token"
                );

          const subscriptionParams = {
            originPolicyId: this.state.subscriptionProgram,
            isActive: true,
            chosenPaymentMethodToken
          };
          this.props.saveSubscriptionForUser(subscriptionParams);
        }
      })
        .catch((err) => {
          console.log(err);
          this.setState({ validatingForm: false });
        });
    }
    );
  };

  isCurrentPaymentMethodRequireCVV = () => {
    const { user = {} } = this.props;
    if (!user.loggedIn) {
      return true;
    }
    const currentPaymentMethod = _.get(user, "paymentMethods.data[0]");
    if (!currentPaymentMethod) {
      return true;
    }

    // in case of a null this is was not defined therefore we will require cvv for any case.
    return currentPaymentMethod.cvvMandatory !== false;
  };

  resetAndNavigateToHome = () => {
    this.props.resetSubscription();
    navigateTo("/");
  };

  render() {
    const {
      appStyles,
      pageContext: {
        business: { currencySymbol, areaCode, openChargeCard, currency },
        businessAppConfiguration: { idRequired },
      },
      T,
      app: { keyboardOpen },
      user = {},
      location,
      subscriptionPolicies,
      paymentTypeDetails,
    } = this.props;

    if (!user.loggedIn || _.isEmpty(subscriptionPolicies)) {
      return null;
    }

    const { userDetails, subscription } = user;
    console.log("subscription", subscription);
    const chosenSubscription = _.find(subscriptionPolicies, policy => _.get(policy, "id") === this.state.subscriptionProgram);

    const activeSubscriptions = _.filter(_.get(user, "loyaltyProfile.data.subscriptions"), subscription => _.get(subscription, "isActive"));

    if (subscription.sending && !this.state.unsubscribed) {
      return (
        <AppContainer.Content appStyles={appStyles}>
          <AppContainer.CenteredColumn>
            <Loader appStyles={appStyles} classNames={styles.Loader} />
            <strong>{T("Signing up for subscription...")}</strong>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    if (subscription.sending && this.state.unsubscribed) {
      return (
        <AppContainer.Content appStyles={appStyles}>
          <AppContainer.CenteredColumn>
            <Loader appStyles={appStyles} classNames={styles.Loader} />
            <strong>{T("Unsubscribing for subscription...")}</strong>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    if (subscription.sent && !_.isEmpty(activeSubscriptions) && !this.state.unsubscribed) {
      return (
        <AppContainer.Content
          appStyles={appStyles}
          classNames={styles.DesktopGutters}
        >
          <AppContainer.CenteredColumn>
            <h2
              style={{
                ...appStyles.PageHeader,
                ...appStyles.TitleOnBackground,
              }}
            >
              {T("Thanks")} {_.get(user, "userDetails.data.name")}!
            </h2>
            <p style={{ marginTop: 0 }}>

            </p>
            <Card appStyles={appStyles}>
              <Card.Title light appStyles={appStyles}>
                {T(`You have successfully signed up to ${_.get(chosenSubscription, "subscriptionTitle")}`)}
              </Card.Title>
              <Card.Content>
                {_.get(chosenSubscription, "subscriptionDescription")}
              </Card.Content>
              <Button
                onClick={this.resetAndNavigateToHome}
                appStyles={appStyles}
                centered
                marginTop
                classNames={styles.BackToHomeDesktop}
              >
                <span>{T("Back to home screen")}</span>
              </Button>
            </Card>
            <AppContainer.Footer
              relativePosition={keyboardOpen}
              appStyles={appStyles}
              transparentGradient={!appStyles.withoutFooterButtonGradient}
              center
              classNames={styles.BackToHomeMobile}
            >
              <AppContainer.Footer.Button
                onClick={this.resetAndNavigateToHome}
                appStyles={appStyles}
                center
                spread
              >
                <span>{T("Back to home screen")}</span>
              </AppContainer.Footer.Button>
            </AppContainer.Footer>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }
    if (subscription.sent && this.state.unsubscribed) {
      return (
        <AppContainer.Content
          appStyles={appStyles}
          classNames={styles.DesktopGutters}
        >
          <AppContainer.CenteredColumn>

            <Card appStyles={appStyles}>
              <Card.Title light appStyles={appStyles}>
                {T(`You have successfully unsubscribed from ${_.get(chosenSubscription, "subscriptionTitle")}`)}
              </Card.Title>
              <Card.Content>
                {T("Your subscription will end in the next payment cycle")}
              </Card.Content>

            </Card>
            <AppContainer.Footer
              relativePosition={keyboardOpen}
              appStyles={appStyles}
              transparentGradient={!appStyles.withoutFooterButtonGradient}
              center
              classNames={styles.BackToHomeMobile}
            >
              <AppContainer.Footer.Button
                onClick={this.resetAndNavigateToHome}
                appStyles={appStyles}
                center
                spread
              >
                <span>{T("Back to home screen")}</span>
              </AppContainer.Footer.Button>
            </AppContainer.Footer>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    if (_.get(user, "loyaltyProfile.data.subscriptions")
      && !_.isEmpty(_.filter(_.get(user, "loyaltyProfile.data.subscriptions"), subscription => _.get(subscription, "isActive")))) {
      return (
        <AppContainer.Content appStyles={appStyles}>
          <AppContainer.CenteredColumn>
            {_.map(_.get(user, "loyaltyProfile.data.subscriptions"), subscription => {
              const thePolicy = _.find(subscriptionPolicies, policy => _.get(policy, "id") === _.get(subscription, "originPolicyId"));

              return (
                <Card appStyles={appStyles}>
                  <Card.Title light appStyles={appStyles}>
                    {T(`You are signed up for ${_.get(thePolicy, "subscriptionTitle")}`)}
                  </Card.Title>


                  {_.get(subscription, "chosenPaymentMethodToken") &&
                    <Card.Content>
                      {_.get(thePolicy, "subscriptionDescription")}

                      <p>
                        {T("Chosen payment method: XXX-")}
                        {_.get(_.find(_.get(this.props.user, "paymentMethods.data"), pm => _.get(pm, "token") === _.get(subscription, "chosenPaymentMethodToken")), "displayString")}
                        {T(" exp: ")}
                        {_.get(_.find(_.get(this.props.user, "paymentMethods.data"), pm => _.get(pm, "token") === _.get(subscription, "chosenPaymentMethodToken")), "expirationMonth")}
                        /
                        {_.get(_.find(_.get(this.props.user, "paymentMethods.data"), pm => _.get(pm, "token") === _.get(subscription, "chosenPaymentMethodToken")), "expirationYear")}
                      </p>
                      <Button
                        onClick={() => {
                          this.setState({ unsubscribed: true });
                          return this.props.unsubscribeFromSubscription({
                            originPolicyId: this.state.subscriptionProgram,
                            isActive: false,
                            chosenPaymentMethodToken: undefined
                          })
                        }}
                        appStyles={appStyles}
                        centered
                        marginTop
                        classNames={styles.BackToHomeDesktop}
                      >
                        <span>{T("Unsubscribe")}</span>
                      </Button>
                    </Card.Content>
                  }
                  {!_.get(subscription, "chosenPaymentMethodToken") &&
                    <Card.Content>{T("You have recently unsubscribed, your subscription will end in the next payment cycle")}</Card.Content>}
                </Card>)
            })}
            <Button
              onClick={this.resetAndNavigateToHome}
              appStyles={appStyles}
              centered
              marginTop
              classNames={styles.BackToHomeDesktop}
            >
              <span>{T("Back to home screen")}</span>
            </Button>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>)
    }

    const { requireZipCode } = paymentTypeDetails || {};
    const requireCVV = this.isCurrentPaymentMethodRequireCVV();
    return (
      <AppContainer.Content
        appStyles={appStyles}
        classNames={styles.DesktopGutters}
      >
        {this.state.shouldLoadPayment && (
          <PaymentAPIScriptLoader
            paymentTypeDetails={paymentTypeDetails}
            onLoad={() =>
              this.setState({
                paymentLoaded: true,
              })
            }
          />
        )}
        {(subscription.error ||
          this.state[`${PAYMENT.PHONE_NUMBER}_ERROR`] ===
          SAME_PHONE_NUMBER_AS_USER_ERROR) && (
            <ErrorBlock classNames={styles.ErrorBlock} appStyles={appStyles}>
              {T(
                `${subscription.error ||
                this.state[`${PAYMENT.PHONE_NUMBER}_ERROR`]}`,
              )}
            </ErrorBlock>
          )}
        <h2
          className={styles.Title}
          style={{
            color: appStyles.accentColor,
            ...appStyles.SubscriptionTitle,
          }}
        >
          {T("Join our subscription program?")}
        </h2>


        {subscriptionPolicies.length > 1 && <Card
          appStyles={appStyles}
        >
          <Card.Title appStyles={appStyles}>
            {T("Select your subscription gift")}
          </Card.Title>

          <Card.Content
            style={{
              margin: "0 16px",
            }}
          >
            <div
              style={{
                marginBottom: 6,
                display: "flex",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <SelectInput
                appStyles={appStyles}
                style={{ width: '100%' }}
                onChange={subscriptionProgram => this.setState({ shouldLoadPayment: true, subscriptionProgram })}
                options={_.map(subscriptionPolicies, policy => {
                  return { label: _.get(policy, "subscriptionTitle"), value: _.get(policy, "id") }
                })}
                value={this.state.subscriptionProgram}
                placeholder={T('Choose your gift')}
                noCheckmark
              />

            </div>

          </Card.Content>
        </Card>}
        {this.state.subscriptionProgram && (<Card appStyles={appStyles} style={{ paddingBottom: "0px" }}>
          <Card.Title appStyles={appStyles}>
            {T(`${_.get(chosenSubscription, "subscriptionTitle")} for ${currencySymbol}${_.get(chosenSubscription, "price")} ${_.get(chosenSubscription, "paymentFrequency")}`)}
          </Card.Title>

          <Card.Content>
            <div>
              {_.get(chosenSubscription, "subscriptionDescription")}
            </div>
          </Card.Content>
        </Card>)}
        {this.state.subscriptionProgram && (<Card appStyles={appStyles} style={{ paddingBottom: "0px" }}>
          <Card.Title appStyles={appStyles}>
            {T("Subscription Terms")}
          </Card.Title>

          <Card.Content>
            {_.get(chosenSubscription, "subscriptionTerms")}
          </Card.Content>
        </Card>)}
        <Card appStyles={appStyles} style={{ paddingBottom: "0px" }}>
          <PaymentMethodInput
            user={this.props.user}
            appStyles={appStyles}
            T={T}
            getInputPropsFor={this.getInputPropsFor}
            changeTargetURL={setParams("/payment-methods", {
              ...getParams(location),
              backPath: "/subscriptions",
            })}
            idRequired={idRequired}
            requireZipCode={requireZipCode}
            requireCVV={requireCVV}
            ref={this.registerInput(PAYMENT.PAYMENT_METHOD_INPUT)}
            hasErrors={this.state.errorComponent === PAYMENT_DETAILS}
            animateError={
              this.state.errorComponent === PAYMENT_DETAILS &&
              this.state.animateErrorElement
            }
            refEl={(ref) => (this.cardRefs[PAYMENT_DETAILS] = ref)}
            paymentTypeDetails={paymentTypeDetails}
            paymentScriptLoaded={this.state.paymentLoaded}
            order={this.props.order}
          />
        </Card>

        <AppContainer.Footer
          relativePosition={keyboardOpen}
          appStyles={appStyles}
          transparentGradient={!appStyles.withoutFooterButtonGradient}
          center
        >
          <AppContainer.Footer.Button
            onClick={this.onSubmit}
            loading={
              !this.props.user.subscription.error &&
              (subscription.sending ||
                subscription.sent ||
                this.state.validatingForm)
            }
            appStyles={appStyles}
            center
            classNames={styles.DesktopGutters}
          >
            {T("Sign up")}
          </AppContainer.Footer.Button>
        </AppContainer.Footer>

      </AppContainer.Content>
    );
  }
}

